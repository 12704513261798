:root {
  --font-family: 'Montserrat', sans-serif;
  --font-family2: "Roboto";
  --w: #ffffff;
  --w2: #ebebeb;
  --g: #999999;
  --g2: #ebebeb;
  --b: #282828;
  --p: #0063a0;
  --s1: #aae3f9;
  --s2: #054466;
  --s3: #cc9955;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

.Mui-TableHeadCell-Content-Wrapper{
  white-space: normal;
  overflow-wrap: normal;
}

.TitulosTNR {
  font-family: "Times New Roman", Times, serif;
  font-size: x-large;
  font-weight: 400;
}

.IconosBotones {
  font-size: 15;
  margin-bottom: "3px";
}

.image-gallery-content .image-gallery-thumbnails-bottom {
  border-radius: 20px;
  width: "20px" !important;
}

.image-gallery {
  border-radius: 20px;
  width: 45%;
}

@media (max-width: 670px) {
  .image-gallery {
    border-radius: 20px;
    width: 100%;
  }
}

@media (max-width: 860px) {
  .image-gallery {
    border-radius: 20px;
    width: 100%;
  }
}

.image-gallery-slide img {
  border-radius: 12px;
  width: 70%;
  height: auto;
  max-height: 80vh;
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
}

.fullscreen .image-gallery-slide img {
  border-radius: 20px;
  max-height: 100vh;
}
.image-gallery-image {
  border-radius: 20px;
}
/* 
.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root {
  z-index: 1302 !important;
} */

/* Custom Scrollbar Style */
::-webkit-scrollbar {
  width: 6px; /* Adjust the width to make it skinny */
}

::-webkit-scrollbar-thumb {
  background-color: grey; /* Change the color to grey */
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: darkgrey; /* Change the color on hover */
}

::-webkit-scrollbar-track {
  background: transparent; /* Make the track transparent */
}

.MuiTableContainer-root .css-n71w8h-MuiTableContainer-root {
  width: 6px;
  background-color: grey;
  background: transparent;
  border-radius: 3px;
}

.title {
  font-weight: bold;
  color: #006db5;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  margin: 20px 0;
}

/* Media query for screens smaller than 1000px */
@media (max-width: 1000px) {
  .title {
    font-size: 22px; /* Decrease the font size for smaller screens */
  }
}

/* Media query for screens larger than 1000px */
@media (min-width: 1001px) {
  .title {
    font-size: 36px; /* Increase the font size for larger screens */
  }
}

/*Lista de opciones de input select*/
/* menu popover */
.MuiPopover-root.MuiMenu-root {
  z-index: 1502 !important;
}

/* menu desplegable de los datepicker */
.MuiPickersPopper-root.MuiPopper-root {
  z-index: 1502 !important;
}

.MuiPickersLayout-root {
  z-index: 1602 !important;
}

body {
  height: 100dvh;
  width: 100%;
  font-family: var(--font-family);
}

body > #root {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.aic {
  display: flex;
  justify-content: center;
  align-items: center;
}
.debug {
  border: 1px dashed red;
}
.anim {
  transition: all 300ms ease-in;
}

.hover:hover {
  cursor: pointer;
}

.scale10:hover {
  transform: scale(1.01);
}
.underline2 {
  background: linear-gradient(0deg, currentColor, currentColor) no-repeat right
    bottom / 0 2px;
  transition: background-size 350ms;
  padding-bottom: 2px;
}
.underline2:where(:hover, :focus-visible) {
  background-size: 100% 2px;
  background-position-x: left;
}

*::-webkit-scrollbar {
  /* display: none; */
  cursor: pointer;
  width: 4px;
  height: 8px;
  box-sizing: border-box;
}

*::-webkit-scrollbar-track {
  border-radius: 3px;
  border: 1px solid transparent;
  background: transparent;
  backdrop-filter: blur(4px);
  /* display: none; */
}

/* *::-webkit-scrollbar-thumb {
  background: #a292a4;
  width: 4px;
  height: 4px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
} */

.shake-bell {
  animation-name: bell-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  display: inline-block;
}
@keyframes bell-animation {
  5% {
    transform: rotate(10deg);
  }

  10% {
    transform: rotate(-10deg);
  }

  15% {
    transform: rotate(10deg);
  }

  20% {
    transform: rotate(0deg);
  }
}
.wave {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}
@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(14deg);
  }

  20% {
    transform: rotate(-8deg);
  }

  30% {
    transform: rotate(14deg);
  }

  40% {
    transform: rotate(-4deg);
  }

  50% {
    transform: rotate(10deg);
  }

  60% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
.tooltip::before {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 0.5rem 0.75rem;
  content: attr(data-tooltip);
  transform: translateY(-35px);
  border-radius: 8px;
  background-color: var(--b);
  color: var(--w);
  transition: 0.3s ease-in-out;
  text-align: center;

  opacity: 0;
  scale: 0;
  visibility: hidden;
  z-index: -1;
}
.tooltip:hover::before {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 0.5rem 0.75rem;
  content: attr(data-tooltip);
  transform: translateY(-35px);
  border-radius: 8px;
  background-color: var(--b);
  color: var(--w);
  transition: 0.3s ease-in-out;
  text-align: center;

  scale: 1;
  opacity: 1;
  visibility: visible;
  z-index: 1;
}
.tooltip[data-tooltip-bottom="true"]::before,
.tooltip[data-tooltip-bottom="true"]:hover::before {
  transform: translateY(35px) !important;
}
.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
}

.rotate {
  animation-name: rotate;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.star-rotate {
  animation-name: rotate;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.54, 0.21, 0.18, 1.35);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fromleft {
  animation-name: tranlateleft;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
}

@keyframes tranlateleft {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

.animhover {
  animation-play-state: paused;
}

.animhover:hover {
  animation-play-state: running;
}
.message-success {
  color: #42bf1e;
  font-weight: bold;
}
.message-error {
  color: #e43f67;
  font-weight: bold;
}

.MuiButton-root {
  border-radius: 20px !important;
}
