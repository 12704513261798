.spinner-full {
  width: 100%;
  height: 100%;
}

.spinner--container {
  gap: 0.5rem;
}
.spinner-img {
  height: 40px;
  width: 40px;
}

.spinner-img2 {
  height: 70px;
  width: 70px;
}
