/* src/index.css or src/App.css */
@font-face {
  font-family: 'Montserrat';
  src: url('/src/assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/src/assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/src/assets/fonts/Montserrat-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}